import React from 'react'
import { Link } from 'gatsby'

import '../styles/episode-card.less'
import { exception } from 'console'

interface Show {
    name: string
}

interface Episode {
    title: string,
    season: number,
    episode: number,
    fields: EpisodeFields
}

interface EpisodeFields {
    slug: string,
    url: string,
    thumbnailUrl: string
}

interface Props {
    episode: Episode,
    show: Show
}

const EpisodeCard = ({episode, show}: Props) => {

    if (episode === undefined || show === undefined) {
        console.error('episode-card error: props underfined.')
    }

    return (
        <div class="col-sm-6 col-md-4 col-lg-3 episode-card">
            <Link to={episode.fields.url}>
                <img class="img-fluid thumbnail" src={episode.fields.thumbnailUrl} width={320} height={180} alt={`Vignette de l'épisode ${episode.title}`} />
                <div class="series-name">{show.name}</div>
                <h5>{episode.episode}. {episode.title}</h5>
                <div class="subtitle">Saison {episode.season}, épisode {episode.episode}</div>
            </Link>
        </div>
    )
}

export default EpisodeCard