import React from 'react'
import { graphql } from 'gatsby'

import EpisodeCard from '../components/episode-card'
import Layout from '../components/layout'

export const query = graphql`
    query ($slug: String!) {
        showsJson(slug: {eq: $slug}) {
            name
        }
        seasons: allEpisodesJson(filter: {show: {eq: $slug}})  {
            group(field: season) {
                fieldValue
            }
        }
        allEpisodesJson(sort: {order: [ASC, ASC], fields: [season, episode]}, filter: {show: {eq: $slug}}) {
            nodes {
                title
                season
                episode
                fields {
                    slug
                    url
                    thumbnailUrl
                }
            }
        }
    }
`

const Show = ({data}) => {
    const show = data.showsJson
    const seasons = data.seasons.group
    const episodes = data.allEpisodesJson.nodes

    return (
        <Layout>
            <h2>{show.name}</h2>
            
            {seasons.map(({fieldValue}) => {
                const seasonEpisodes = episodes.filter(episode => episode.season === parseInt(fieldValue))
                return [
                    <h3>Saison {fieldValue}</h3>,
                    <div class="row">
                        {seasonEpisodes.map((episode) => (
                            <EpisodeCard episode={episode} show={show} />
                        ))}
                    </div>
                ]
            })}
            
                
           
        </Layout>
    )
}

export default Show